var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "img" },
    [
      _c(
        "a-upload",
        {
          class: !_vm.isMultiple ? "imgupload" : "",
          attrs: {
            name: "file",
            listType: "picture-card",
            multiple: _vm.isMultiple,
            action: _vm.uploadAction,
            headers: _vm.headers,
            data: { biz: _vm.bizPath },
            fileList: _vm.fileList,
            beforeUpload: _vm.beforeUpload,
            disabled: _vm.disabled,
            isMultiple: _vm.isMultiple,
            showUploadList: _vm.isMultiple,
          },
          on: { change: _vm.handleChange, preview: _vm.handlePreview },
        },
        [
          _c(
            "div",
            {
              style: {
                width: !_vm.isMultiple ? "104px" : "auto",
                height: !_vm.isMultiple ? "104px" : "auto",
              },
            },
            [
              !_vm.isMultiple && _vm.picUrl
                ? _c("img", {
                    staticStyle: { width: "100%", height: "100%" },
                    attrs: { src: _vm.getAvatarView() },
                  })
                : _c(
                    "div",
                    { staticClass: "iconp" },
                    [
                      _c("a-icon", {
                        attrs: { type: _vm.uploadLoading ? "loading" : "plus" },
                      }),
                      _c("div", { staticClass: "ant-upload-text" }, [
                        _vm._v(_vm._s(_vm.text)),
                      ]),
                    ],
                    1
                  ),
            ]
          ),
          _c(
            "a-modal",
            {
              attrs: { visible: _vm.previewVisible, footer: null },
              on: {
                cancel: function ($event) {
                  return _vm.handleCancel()
                },
              },
            },
            [
              _c("img", {
                staticStyle: { width: "100%" },
                attrs: { alt: "example", src: _vm.previewImage },
              }),
            ]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }